<template>
  <base-material-card icon="mdi-clipboard-text" :title="$t('menu.refund.list')" class="px-5 py-3">
    <div class="position: relative" style="margin-top: -70px">
      <base-add-button :permissions="permissions" :basePath="basePath" :title="$t('refund.add')">
      </base-add-button>
    </div>
    <base-dropdown-filter
      :items="filters"
      @changeFilter="changeFilter"
      label="Status Refund"
      style="margin-top: -12px"
    >
    </base-dropdown-filter>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
          <th class="primary--text">
            {{ $t('refund.orderNumber') }}
          </th>
          <th class="primary--text">
            {{ $t('refund.refundAmount') }}
          </th>
          <th class="primary--text">
            {{ $t('refund.refundNumber') }}
          </th>
          <th class="primary--text">
            {{ $t('refund.createdBy') }}
          </th>
          <th class="primary--text">
            {{ $t('refund.status') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.uuid">
          <td>
            <base-detail-button
              :permissions="permissions"
              :basePath="basePath"
              :id="item.uuid"
            ></base-detail-button>
          </td>
          <td>{{ item.order_number }}</td>
          <td>{{ item.refund_amount ? $n(item.refund_amount, 'currency', 'id-ID') : '' }}</td>
          <td>{{ item.refund_number }}</td>
          <td>{{ item.created_by }}</td>
          <td>{{ item.status }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions', 'filters'],
  components: {
    Pagination,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.refundInitiator.basePath,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeFilter(val) {
      this.$emit('changeFilter', val);
    },
  },
};
</script>
